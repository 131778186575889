// themes

export const light = {
body: "#000", // black
text:"#fff", // white
bodyRgba : "255, 255, 255",
textRgba: "255 , 255, 255", //white for subtitle
carouselColor: '#2d1f32',
fontxs:'0.75em',
fontsm:'0.875em',
fontmd:'lem', // 1em = 16px
fontlg:'1.25em',
fontxl:'2em',
fontxxl:'3em',
fontxxxl:'4em',
fontButton:'0.875em',
navHeight: '5rem',
}

export const dark = {
body: "#fff",
text:"#202020", // black shade
bodyRgba : "32,32,32",
textRgba: "255, 255, 255",
carouselColor: '#EEEDDE',
fontxs:'0.75em',
fontsm:'0.875em',
fontmd:'lem', // 1em = 16px
fontlg:'1.25em',
fontxl:'2em',
fontxxl:'3em',
fontxxxl:'4em',
fontButton:'0.875em',
navHeight: '5rem',
}