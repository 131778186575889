import { createGlobalStyle } from "styled-components";
import "@fontsource/sora"
import "@fontsource/big-shoulders-text"
import "@fontsource/electrolize"

const GlobalStyles = createGlobalStyle `

*,*::before,*::after {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Electrolize', sans-serif;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
}

a {
    color: inherit;
    text-decoration: none;
}



`
export default GlobalStyles;