import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Carousel from '../Carousel'
import Button from '../Button'
import {dark} from '../../styles/Themes';



const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: #120019;
// background: linear-gradient(-45deg, #6f1e8e, #2f0a3d, #211725, #000);
display: flex;
justify-content: center;
align-items: center;
position: relative;
`
const Container = styled.div`
width: 75%;
// min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  width: 100%;
  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const Title = styled.h2`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: white;
align-self: flex-start;
width: 80%;
margin: 0 auto;
text-align: center;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
font-size: ${(props) => props.theme.fontlg};
color: white;
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight:400;
text-align: center;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
text-align: center;
font-size: ${(props) => props.theme.fontmd};
color: white;
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
width: 100%;
text-align:center;
font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}
`
const ButtonContainer = styled.div`
width: 80%;
margin: 1rem auto;
display: flex;
text-align: center;
align-items: center;
align-self:

@media (max-width: 64em){
width: 100%;

button{
  margin: 0 auto;
}
}

`



const About = () => {
  return (
    <Section>
      <Container>
        <Box> <Carousel /> </Box>
        <Box>
          <Title>
            Welcome to Orion Services
          </Title> 
          <SubText>
            Lorem Ipsum, Lorem Ipsum, Lorem Ipsum,Lorem Ipsum
          </SubText>
          <SubTextLight>
          Lorem Ipsum, Lorem Ipsum, Lorem Ipsum,Lorem Ipsum
          </SubTextLight>
                  <ButtonContainer>
        <ThemeProvider theme={dark}>
        <Button text="JOIN OUR DISCORD" link="#" />
        </ThemeProvider>
        </ButtonContainer>
           </Box>
        {/* <Box> Carousel </Box> */}
      </Container>
    </Section>
  )
}
export default About