import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LogoText = styled.h1`
font-family: 'Big Shoulders Text';
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all 0.2s ease;

img {
  width: 250px;
  max-width: 250px;
}

&:hover {
  transform: scale(1.1);
}
@media (max-width: 64em){
  
}
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="/">
        <img src="https://media.discordapp.net/attachments/941736914162155580/947988412684718100/Orion-Services-Logo.png?width=702&height=702" alt="orion"></img>
        </Link>
    </LogoText>
  )
}

export default Logo