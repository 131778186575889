import React from 'react'
import TypeWriterText from '../TypeWriterText'
import styled, { keyframes } from 'styled-components'
import CoverVideo from '../CoverVideo'
import RoundTextBlack from '../../assets/Rounded-Text-White.png';

const gradient = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`

const Section = styled.section ` 
min-height: ${props => `calc(100vh ${props.theme.navHeight})` };
width: 100vw;
position: relative;
background-color: #120019;
animation: gradient 15s ease infinite;
// background: linear-gradient(-45deg, #000, #211725, #2f0a3d, #6f1e8e);
animation: ${gradient} 15s ease infinite;
`

const Container = styled.div`
width: 75%;
min-height: 92vh;
margin: 0 auto;
background-color: background: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
`
const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`

const Round = styled.div`
position: absolute;
bottom: 2rem;
right: 90%;
width: 6rem;
height: 6rem;
border: 1px solid ${props => props.theme.text};
border-radius: 50%;

img{
width: 100%;
height: auto;
animation: ${rotate} 6s linear infinite reverse ;
`
const Circle = styled.span`
width: 3rem;
height: 3rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);

background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
font-size:${props => props.theme.fontxl};

@media (max-width: 64em) {
  width: 2rem;
  height: 2rem;
font-size:${props => props.theme.fontlg};

}
`

const Home = () => {
  return (
    <Section>
      <Container>
      <Box> <TypeWriterText /> </Box>
      <Box> <CoverVideo /> </Box>
      {/* <Box> <h1>text</h1> </Box> */}

    
    <Round>
      <Circle>
        &#x2193; 
      </Circle>
      <img src={RoundTextBlack} alt="ORION" />
    </Round>
    
    </Container>
  </Section>
  )
}

export default Home