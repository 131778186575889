import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Button from './ButtonDiscord'

const Section = styled.section `
width: 100vw;
background-color: ${props => props.theme.body};
// background: linear-gradient(-45deg, #000, #211725, #2f0a3d, #6f1e8e);
color: inherit;
position: sticky;
top: 0;
z-index: 999;
`
const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;
width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;
position: sticky;
top: 0;
`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
margin: 0 auto;
`
const MenuItem = styled.li`
margin: 0 1rem;
color: ${props => props.theme.text};
cursor: pointer;

&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  // background: ${props => props.theme.text};
  background: linear-gradient(-45deg, #000, #6f1e8e, #6f1e8e, #6f1e8e);
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
  background: linear-gradient(-45deg, #000, #6f1e8e, #6f1e8e, #6f1e8e);
}

@media (max-width: 64em) {
margin: 1rem 0;

&::after{
  display: none;
}

}
`

const Navigation = () => {
  return (
    
    <Section>
      <NavBar>
        <Logo />
        <Menu>
            <MenuItem>Home</MenuItem>
            <MenuItem>About</MenuItem>
            <MenuItem>Roadmap</MenuItem>
            <MenuItem>Showcase</MenuItem>
            <MenuItem>Team</MenuItem>
            <MenuItem>Faq</MenuItem>
        </Menu>
        <Button text="Join Our Discord" link="https://discord.gg/kayfmNTAmp" />
      </NavBar>
    </Section>
    
  )
}

export default Navigation